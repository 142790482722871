<template>
  <div class="fill-in-wrap">
    <div class="tips" v-if="tips">
      <span><van-icon name="info-o" /></span>
      {{ tips }}
    </div>
    <div class="content">
      <div class="input-wrap">
        <van-field
          v-model="passstr"
          label="人像活体验证"
          required
          input-align="right"
          is-link
          disabled
          @click="toCertification"
        />
      </div>
    </div>
    <div class="btns">
      <van-button type="info" size="small" @click="toCertification"
        >开始认证</van-button
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tips: "",
      pass: "",
      imgs: []
    };
  },
  components: {},
  computed: {
    passstr() {
      return this.pass == "VERIFIED" ? "已验证" : "未验证或验证未通过";
    }
  },
  mounted() {
    const bizNo = this.$route.query.bizNo;
  },
  methods: {
    async toCertification() {
      const bizNo = this.$route.query.bizNo;
      this.$router.push(`/identity-portrait-face?bizNo=${bizNo}`);
    }
  }
};
</script>
<style lang="scss" scoped>
.fill-in-wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  .tips {
    padding: 12px 24px;
    line-height: 40px;
    font-size: 24px;
    color: #f5a227;
    background: #fff1dc;
    min-height: 48px;
    box-shadow: 0 5px 5px #72634c15;
    margin-bottom: 5px;
  }
  .head {
    height: 96px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    font-weight: 600;
    color: #333;
    border-bottom: 1px solid #efefef;
  }
  .content {
    flex: 1;
    overflow-y: auto;

    .input-wrap {
      width: 100%;
      min-height: 96px;
      display: flex;
      align-items: center;
      overflow: hidden;
      padding: 0 32px;
      :deep(.van-field__label) {
        width: 7em;
      }
    }
    .color-r {
      color: red;
    }
    .color-blue {
      color: rgb(24, 144, 255);
    }
  }
  .btns {
    border-top: 1px solid #efefef;
    height: 112px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 0 -5px 10px #efefef;
    .van-button {
      width: 45%;
      height: 75%;
    }
  }
}
</style>
